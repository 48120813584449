exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-galeria-babafotozas-tsx": () => import("./../../../src/pages/galeria/babafotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-babafotozas-tsx" */),
  "component---src-pages-galeria-csaladi-fotozas-tsx": () => import("./../../../src/pages/galeria/csaladi-fotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-csaladi-fotozas-tsx" */),
  "component---src-pages-galeria-eskuvo-fotozas-tsx": () => import("./../../../src/pages/galeria/eskuvo-fotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-eskuvo-fotozas-tsx" */),
  "component---src-pages-galeria-gyerekfotozas-tsx": () => import("./../../../src/pages/galeria/gyerekfotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-gyerekfotozas-tsx" */),
  "component---src-pages-galeria-kutyafotozas-tsx": () => import("./../../../src/pages/galeria/kutyafotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-kutyafotozas-tsx" */),
  "component---src-pages-galeria-portrefotozas-tsx": () => import("./../../../src/pages/galeria/portrefotozas.tsx" /* webpackChunkName: "component---src-pages-galeria-portrefotozas-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

